<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AppInputGroup from "@/features/general/InputGroup";
import AppIcon from "@/features/general/Icons";
import debounce from "lodash/debounce";
import { mask } from "vue-the-mask";
import useVuelidate from "@vuelidate/core";
import { required, email, requiredIf } from "@vuelidate/validators";
import {
  hasLastName,
  isValidLength,
  isBirthDate,
  isValidByMaxAge,
  isValidByMinAge,
  isValidPhoneNumber,
  isValidForeignPhoneNumber,
  isCpfFormatValid,
} from "./validators/personal";
import mixinPhoneMask from "@/mixins/phoneMask";

export default {
  name: "AppCustomerPersonalEdit",
  mixins: [mixinPhoneMask],
  components: {
    AppInputGroup,
    AppIcon,
  },
  props: {
    loading: Boolean,
    customer: {
      type: Object,
      default() {
        return {
          email: "",
          id: "",
          mobile_phone: "",
          name: "",
          phone: "",
          rg: "",
          gender: "",
          type: "",
          cpf: "",
          birth_date: "",
          newsletter: 0,
          is_foreign: false,
        };
      },
    },
  },

  directives: {
    mask,
  },

  setup() {
    /**
     * Objeto que mantém o estado das validações do vuelidate
     */
    return { v$: useVuelidate({ $autoDirty: true }) };
  },

  data() {
    return {
      ONLY_ONE_PHONE_REQUIRED: "3",
      ONLY_CELLPHONE_REQUIRED: "2",
      ONLY_PHONE_REQUIRED: "1",
      SHOW_PHONE: 1,
      FIXED_LINE: 0,
      MOBILE: 1,
      DEFAULT_MIN_AGE: 0,
      DEFAULT_MAX_AGE: 122,
      ACCEPTANCE_NEWSLETTER_TERM: 2,
      DECLINE_NEWSLETTER_TERM: 1,
      emailAvailable: true,
      emailPending: false,
      cpfAvailable: true,
      cpfPending: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.setIsPersonalDataInvalid({ value: this.v$.$invalid });
    });

    this.$bus.on("submit-data", () => {
      this.getInputCpf = this.customer.cpf;
      this.v$.$reset();
    });

    if (!this.gender && !this.isFilledGenderRequired) {
      this.gender = '3';
    }

    this.v$.$touch();
  },

  methods: {
    ...mapActions("Customer", [
      "setData",
      "setIsPersonalDataInvalid",
      "clearBackendErrors",
      "setVuelidateErrors",
    ]),

    /**
     * Verifica se a loja está configurada para exibir o
     * telefone celular do usuário
     *
     * @return {boolean}
     */
    showCellphone() {
      if (this.loading) {
        return true;
      }

      const { show_customer_cellphone } = this.storeSettings;
      return show_customer_cellphone == this.SHOW_PHONE;
    },

    /**
     * Verifica se a loja está configurada para exibir o
     * telefone fixo do usuário
     *
     * @returns {boolean}
     */
    showPhone() {
      if (this.loading) {
        return true;
      }

      const { show_customer_phone } = this.storeSettings;
      return show_customer_phone == this.SHOW_PHONE;
    },

    /**
     * Muda o termo de aceite da nesletter entre aceito e
     * declinado.
     * @param {object} event Evento de ticar o checkbox
     * @return {undefined}
     */
    toggleNewsletterTerm(event) {
      const newletterTermElement = event.target;
      if (newletterTermElement.checked) {
        this.newsletter = this.ACCEPTANCE_NEWSLETTER_TERM;
        return;
      }

      this.newsletter = this.DECLINE_NEWSLETTER_TERM;
    },

    /**
     * Verifica se o email está disponível para cadastro
     * @param {event} event evento disparado no input
     * @return {boolean}
     */
    // eslint-disable-next-line
    validateEmailDuplicity: debounce(function (event) {
      const currentEmail = event.target.value;

      if (!currentEmail || !this.v$.email.email.$response) {
        return false;
      }

      this.emailPending = true;

      this.$http
        .get(`/customer/${this.customer.id}/validate/email/${currentEmail}`)
        .then(() => {
          this.emailPending = false;
          this.emailAvailable = true;
        })

        .catch(() => {
          this.emailPending = false;
          this.emailAvailable = false;
        });
    }, 750),

    /**
     * Verifica se o cpf está disponível para cadastro
     * @param {event} event evento disparado no input
     * @return {boolean}
     */
    // eslint-disable-next-line
    validateCpfDuplicity: debounce(function (event) {
      const cpf = event.target.value;
      const isEmptyOptional = this.cpf.length === 0 && !this.isCpfRequired;

      if (!cpf || !this.v$.cpf.isCpfFormatValid.$response || isEmptyOptional) {
        this.cpfPending = false;
        this.cpfAvailable = true;
        return true;
      }

      this.cpfPending = true;
      this.$http
        .get(`/customer/${this.customer.id}/validate/cpf/${cpf}`)
        .then(() => {
          this.cpfPending = false;
          this.cpfAvailable = true;
        })
        .catch(() => {
          this.cpfPending = false;
          this.cpfAvailable = false;
        });
    }, 750),

    /**
     * Retorna o state de acordo com as opções do validador
     * https://monterail.github.io/vuelidate/
     *
     * @param {object} v$.[model]
     * @param {string} validationMessage Mensagem de validação customizada
     * @returns {boolean|string}
     */
    getValidatorState(
      { $dirty, $error, $invalid, $model, $path } = {},
      validationMessage,
      modelName = null
    ) {
      if ($error !== this.vuelidateErrors[$path]) {
        this.setVuelidateErrors({ [$path]: $error });
      }

      if (modelName && this.isInitialByRequiredness(modelName, $model)) {
        return "initial";
      }

      if (validationMessage && validationMessage.length) {
        return "invalid";
      }

      if ($dirty) {
        return !$error;
      }

      const isNeutral = !$error && ($invalid || !$model);
      return isNeutral ? "initial" : !$invalid;
    },

    /**
     * Verifica se o estado do campo pode ser considerado [initial] quando
     * ele estiver vazio e não for obrigatório
     * @param {string} modelName Nome do campo
     * @param {string} modelValue Valor do campo
     * @return {bool}
     */
    isInitialByRequiredness(modelName, modelValue) {
      const isInitialState =
        modelValue.length === 0 &&
        ((modelName === "cpf" && !this.isCpfRequired) ||
          (modelName === "rg" && !this.isRgRequired) ||
          (modelName === "birth_date" && !this.isBirthDateRequired));

      return isInitialState;
    },

    /**
     * Verifica se o tipo de pessoa atual é o mesmo da configuração de
     * obrigatoriedade do campo
     * @param {string|bool} config Valor da configuração
     */
    isSamePersonTypeAndFieldConfig(config) {
      return this.customer.type === config;
    },

    /**
     * Verifica se o campo CPF será editável
     * @return {boolean}
     */
    isReadOnlyCpf() {
      if (this.loading) {
        return false;
      }

      const hasPreviousCpf = Boolean(sessionStorage.getItem("hasPreviousCpf"));
      return hasPreviousCpf;
    },

    /**
     * Valida se o número de telefone é valido
     *
     * @param {string} required_type Tipo do número. 0 = FIXED_LINE | 1 = MOBILE
     * @param {string} phone_number Número de telefone
     * @returns {boolean}
     */
    validatePhone(required_type, phone_number) {
      if (this.requiredPhoneValidate(required_type) && phone_number.length) {
        return this.isForeignPhoneNumber(required_type);
      }

      return true;
    },

    /**
     * Valida se o número é internacional e
     * retorna o validador que deve ser usado
     *
     * @param {string} required_type Tipo do número. 0 = FIXED LINE | 1 = MOBILE
     * @returns {boolean}
     */
    isForeignPhoneNumber(required_type) {
      if (this.isNationalNumber) {
        return isValidPhoneNumber(required_type);
      }

      return isValidForeignPhoneNumber(required_type);
    },

    /**
     * Escolhe uma função de acordo com o tipo de
     * telefone para validar se é obrigatório
     *
     * @param {string} required_type Tipo do número. 0 = FIXED_LINE | 1 = MOBILE
     * @returns {boolean}
     */
    requiredPhoneValidate(required_type) {
      const { mainPhone, secondaryPhone, validateFunction } =
        this.dataToValidatePhone(required_type);

      if (
        validateFunction ||
        this.hasPhoneAndHasMinLength(mainPhone) ||
        this.onlyRequiredAndHasMinLength(secondaryPhone)
      ) {
        return required;
      }

      return false;
    },

    /**
     * Retorna diferentes informações para validação de telefone fixo ou celular
     *
     * @param {string} required_type Tipo do número. 0 = FIXED_LINE | 1 = MOBILE
     * @return {object} { mainPhone, secondaryPhone, validateFunction }
     */
    dataToValidatePhone(required_type) {
      const phones = {
        0: {
          mainPhone: this.phone,
          secondaryPhone: this.cellphone,
          validateFunction: this.isOnlyPhoneRequired,
        },
        1: {
          mainPhone: this.cellphone,
          secondaryPhone: this.phone,
          validateFunction: this.isOnlyCellphoneRequired,
        },
      };

      return phones[required_type];
    },

    /**
     * Valida se tem telefone e se tem o número mínimo de caracteres
     * @param {string} phone
     * @returns {boolean}
     */
    hasPhoneAndHasMinLength(phone) {
      return phone.length && phone.length < 13;
    },

    /**
     * Valida de apenas um telefone é obrigatório e se o
     * telefone tem um tamanho minímo de caracteres
     * @returns {boolean}
     */
    onlyRequiredAndHasMinLength(phone) {
      return this.isOnlyOnePhoneRequired && phone.length < 13;
    },

    /**
     * Valida o número internacional, trazendo a mensagem de
     * telefone obrigatório caso tenha apenas um "+" no campo
     *
     * @returns {boolean}
     */
    validLengthForeignPhone(phone) {
      if (!this.isNationalNumber && phone.length <= 1) {
        return true;
      }

      return !this.v$.cellphone.required.$response;
    },
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState({
      storeSettings: (state) => state.store_info.list.settings.store,
    }),
    ...mapState("Customer", {
      backendErrors: (state) => state.customer.backendErrors,
      vuelidateErrors: (state) => state.customer.vuelidateErrors,
    }),

    /**
     * Retorna a cor de background do input de cpf
     * @returns {string}
     */
    cpfInputBackground() {
      if (this.isReadOnlyCpf() && !this.loading) {
        return "#e9ecef";
      }
      return "";
    },

    /**
     * Verifica se o termo de newsletter está aceito
     * @return {boolean}
     */
    isNewsletterAccepted() {
      return this.customer.newsletter === this.ACCEPTANCE_NEWSLETTER_TERM;
    },

    isForeign: {
      get() {
        return this.customer.is_foreign;
      },
      set(is_foreign) {
        this.setData({ list: { is_foreign } });
      },
    },

    email: {
      get() {
        return this.customer.email;
      },
      set(email) {
        this.setData({ list: { email } });
      },
    },

    name: {
      get() {
        return this.customer.name;
      },
      set(name) {
        this.setData({ list: { name } });
      },
    },

    cpf: {
      get() {
        return this.customer.cpf;
      },
      set(cpf) {
        this.setData({ list: { cpf } });
      },
    },

    birth_date: {
      get() {
        return this.customer.birth_date;
      },
      set(birth_date) {
        this.setData({ list: { birth_date } });
      },
    },

    rg: {
      get() {
        return this.customer.rg;
      },
      set(rg) {
        this.setData({ list: { rg } });
      },
    },

    gender: {
      get() {
        return this.customer.gender;
      },
      set(gender) {
        this.setData({ list: { gender } });
      },
    },

    phone: {
      get() {
        return this.phoneMask(this.customer.phone);
      },
      set(phone) {
        const formatedPhone = this.phoneMask(phone);
        this.setData({ list: { phone: formatedPhone } });
      },
    },

    cellphone: {
      get() {
        return this.phoneMask(this.customer.mobile_phone);
      },
      set(mobile_phone) {
        const formatedPhone = this.phoneMask(mobile_phone);
        this.setData({ list: { mobile_phone: formatedPhone } });
      },
    },

    newsletter: {
      get() {
        return this.customer.newsletter;
      },
      set(newsletter) {
        this.setData({ list: { newsletter } });
      },
    },

    /**
     * Verifica se o CPF é obrigatório baseado nas configurações da loja
     * @return {bool}
     */
    isCpfRequired() {
      const docsRequiredForPf = this.storeSettings.documents_requiredness_pf;
      const docsRequiredForPj = this.storeSettings.documents_requiredness_pj;
      const type = this.customer.type;

      const isRequired =
        (type === "pf" && docsRequiredForPf === "cpf") ||
        (type === "pj" && docsRequiredForPj === "both");

      if (this.isForeign) {
        return false;
      }

      return isRequired;
    },

    /**
     * Verifica se o sexo é obrigatório baseado nas configurações da loja
     * @return {bool}
     */
    isGenderRequired() {
      const genderConfig = this.storeSettings.gender_requiredness;
      const isRequired =
        genderConfig === "both" ||
        this.isSamePersonTypeAndFieldConfig(genderConfig);

      return isRequired;
    },

    /**
     * Verifica se é obrigatório o preenchimento do campo sexo baseado nas configurações da loja
     * @return {bool}
     */
    isFilledGenderRequired() {
      return !!this.storeSettings.enable_gender_required;
    },

    /**
     * Verifica se o rg é obrigatório baseado nas configurações da loja
     * @return {bool}
     */
    isRgRequired() {
      if (this.isForeign) {
        return false;
      }

      const rgConfig = this.storeSettings.rg_requiredness;
      const isRequired =
        rgConfig === "both" || this.isSamePersonTypeAndFieldConfig(rgConfig);

      return isRequired;
    },

    /**
     * Verifica se a data de nascimento é obrigatória baseado nas configurações da loja
     * @return {bool}
     */
    isBirthDateRequired() {
      const isBirthDateRequired = this.storeSettings.birth_date_is_required;
      const requirednessOption = this.storeSettings.birth_date_requiredness;

      if (!isBirthDateRequired) {
        return false;
      }

      const isRequired =
        requirednessOption === "both" ||
        this.isSamePersonTypeAndFieldConfig(requirednessOption);

      return isRequired;
    },

    /**
     * Valida se qualquer um dos dois telefones é requerido
     * @returns {boolean}
     */
    isOnlyOnePhoneRequired() {
      const { required_phones } = this.storeSettings;
      return required_phones == this.ONLY_ONE_PHONE_REQUIRED;
    },

    /**
     * Valida se o telefone fixo é obrigatório
     * @returns {boolean}
     */
    isOnlyPhoneRequired() {
      const { required_phones } = this.storeSettings;
      return required_phones == this.ONLY_PHONE_REQUIRED;
    },

    /**
     * Valida se o celular é obrigatório
     * @returns {boolean}
     */
    isOnlyCellphoneRequired() {
      const { required_phones } = this.storeSettings;
      return required_phones == this.ONLY_CELLPHONE_REQUIRED;
    },

    /**
     * Gera a mensagem de idade máxima
     * @return {string}
     */
    definedMaxAge() {
      const textMaxAge = `${this.langs.profile["max-age"]}`;
      const textYearsOld = `${this.langs.profile["years-old"]}`;
      const storeMaxAge = `${this.storeSettings.max_age}`;

      return `${textMaxAge} ${
        storeMaxAge || this.DEFAULT_MAX_AGE
      }${textYearsOld}`;
    },

    /**
     * Gera a mensagem de idade mínima
     * @return {string}
     */
    definedMinAge() {
      const textMaxAge = `${this.langs.profile["min-age"]}`;
      const textYearsOld = `${this.langs.profile["years-old"]}`;
      const storeMaxAge = `${this.storeSettings.min_age}`;

      return `${textMaxAge} ${
        storeMaxAge || this.DEFAULT_MIN_AGE
      }${textYearsOld}`;
    },
  },

  validations() {
    return {
      email: {
        required,
        email,
        isEmailAvailable() {
          return this.emailAvailable;
        },
      },
      name: {
        required,
        hasLastName,
      },
      cpf: {
        required: requiredIf(() => this.isCpfRequired),
        isCpfFormatValid: isCpfFormatValid(this.isCpfRequired),
        isCpfValid() {
          return this.cpfAvailable;
        },
      },
      birth_date: {
        required: requiredIf(() => this.isBirthDateRequired),
        isBirthDate: isBirthDate(this.isBirthDateRequired),
        isValidByMaxAge: isValidByMaxAge(this.storeSettings.max_age),
        isValidByMinAge: isValidByMinAge(this.storeSettings.min_age),
      },
      phone: {
        required: this.requiredPhoneValidate(this.FIXED_LINE),
        isValidPhoneNumber: this.validatePhone(this.FIXED_LINE, this.phone),
      },
      cellphone: {
        required: this.requiredPhoneValidate(this.MOBILE),
        isValidPhoneNumber: this.validatePhone(this.MOBILE, this.cellphone),
      },
      rg: {
        required: requiredIf(() => this.isRgRequired),
        isValidLength: isValidLength(5, 15, this.isRgRequired),
      },
      gender: {
        required: requiredIf(() => this.isGenderRequired || this.isFilledGenderRequired),
      },
    };
  },

  watch: {
    // @see Uso de function(): https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks
    // eslint-disable-next-line
    "v$.$invalid": function () {
      this.setIsPersonalDataInvalid({ value: this.v$.$invalid });
    },
    phone() {
      if (this.requiredPhoneValidate(this.FIXED_LINE)) {
        this.v$.cellphone.$reset();
      }
    },
    cellphone() {
      if (this.requiredPhoneValidate(this.MOBILE)) {
        this.v$.phone.$reset();
      }
    },
    isForeign(value) {
      if (value) {
        this.rg = "";
        this.v$.rg.$reset();
      }
    },
  },
};
</script>
<template>
  <section class="app__customer-personal-edit">
    <h6 v-if="isForeign">
      <span class="badge badge-international">{{
        this.langs.profile["international-account"]
      }}</span>
    </h6>
    <h3 class="app__customer__title app__customer__title--3">
      {{ this.langs.profile["personal-data"] }}
    </h3>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-input-group
          :label="`${this.langs.profile['email']}`"
          identifier="customer-email"
          :icon="{ prefix: 'far', name: 'envelope' }"
          :loading="loading"
          :value="email"
          :state="
            emailPending
              ? 'pending'
              : getValidatorState(v$.email, backendErrors.email)
          "
        >
          <template v-slot:input>
            <input
              type="email"
              placeholder="E-mail"
              id="customer-email"
              class="app__input-group__input form-control"
              :class="{
                'app__input-group__input--is-invalid': v$.email.$error,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !email,
                app__loading: loading,
              }"
              v-model="email"
              @change="v$.email.$touch()"
              @input="validateEmailDuplicity"
            />
          </template>
          <template v-slot:feedbacks>
            <small v-if="v$.email.$error" class="app__input-group__feedbacks">
              <span
                v-if="!v$.email.required.$response"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["inform-email"] }}
              </span>
              <span
                class="app__input-group__feedbacks__feedback"
                v-else-if="
                  v$.email.required.$response && !v$.email.email.$response
                "
              >
                {{ this.langs.profile["valid-email"] }}
              </span>
              <span
                class="app__input-group__feedbacks__feedback"
                v-else-if="
                  v$.email.required.$response &&
                  v$.email.email.$response &&
                  !v$.email.isEmailAvailable.$response
                "
              >
                {{ this.langs.profile["email-already-in-use"] }}
              </span>
            </small>
          </template>
          <template v-slot:feedbacks--backend>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.email"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.email[0] }}
              </span>
            </small>
          </template>
        </app-input-group>

        <app-input-group
          :label="this.langs.profile['placeholder-name']"
          identifier="customer-name"
          :icon="{ prefix: 'far', name: 'user' }"
          :loading="loading"
          :value="name"
          :state="this.getValidatorState(v$.name, backendErrors.name)"
        >
          <template v-slot:input>
            <input
              type="text"
              :placeholder="this.langs.profile['full-name']"
              id="customer-name"
              :class="{
                'form-control': true,
                'app__input-group__input': true,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !name,
                app__loading: loading,
              }"
              v-model="name"
              @change="v$.name.$touch()"
            />
          </template>

          <template v-slot:feedbacks>
            <small v-if="v$.name.$error" class="app__input-group__feedbacks">
              <span
                v-if="!name && !v$.name.required.$response"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["inform-name"] }}
              </span>
              <span
                v-if="name && !v$.name.hasLastName.$response"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["valid-name"] }}
              </span>
            </small>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.name"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.name[0] }}
              </span>
            </small>
          </template>
        </app-input-group>
        <span
          class="input-group__related-span"
          id="cpf-tooltip__related-span"
          v-if="!isForeign"
        >
          <app-input-group
            label="CPF (123.456.789-10)"
            identifier="customer-cpf"
            :loading="loading"
            :value="cpf"
            :icon="{ prefix: 'far', name: 'address-card' }"
            :state="
              cpfPending
                ? 'pending'
                : this.getValidatorState(v$.cpf, backendErrors.cpf, 'cpf')
            "
          >
            <template v-slot:input>
              <input
                type="tel"
                placeholder="CPF"
                id="customer-cpf"
                :class="{
                  'form-control': true,
                  'app__input-group__input': true,
                  'app__input-group__input--has-float-label': true,
                  'app__input-group__input--is-empty': !cpf,
                  app__loading: loading,
                }"
                v-model="cpf"
                :style="{ background: cpfInputBackground }"
                :readonly="isReadOnlyCpf()"
                @change="v$.cpf.$touch()"
                :input="validateCpfDuplicity"
              />
            </template>

            <template v-slot:feedbacks>
              <small v-if="v$.cpf.$error" class="app__input-group__feedbacks">
                <span
                  v-if="!v$.cpf.required.$response"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{ this.langs.profile["inform-cpf"] }}
                </span>
                <span
                  class="app__input-group__feedbacks__feedback"
                  v-else-if="
                    v$.cpf.required.$response &&
                    cpf.length > 0 &&
                    !v$.cpf.isCpfFormatValid.$response
                  "
                >
                  {{ this.langs.profile["valid-cpf"] }}
                </span>
                <span
                  class="app__input-group__feedbacks__feedback"
                  v-else-if="
                    v$.cpf.required.$response &&
                    cpf.length > 0 &&
                    v$.cpf.isCpfFormatValid.$response &&
                    !v$.cpf.isCpfValid.$response
                  "
                >
                  {{ this.langs.profile["cpf-already-in-use"] }}
                </span>
              </small>
              <small
                class="app__input-group__feedbacks app__input-group__feedbacks--backend"
              >
                <span
                  v-if="backendErrors.cpf"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{ backendErrors.cpf[0] }}
                </span>
              </small>
            </template>
          </app-input-group>
        </span>
        <app-input-group
          :label="`${this.langs.profile['placeholder-birthday']}`"
          identifier="customer-birthdate"
          :loading="loading"
          :value="birth_date"
          :icon="{ prefix: 'far', name: 'calendar' }"
          :state="
            this.getValidatorState(
              v$.birth_date,
              backendErrors.birth_date,
              'birth_date'
            )
          "
        >
          <template v-slot:input>
            <input
              type="tel"
              :placeholder="`${this.langs.profile['birthday']}`"
              id="customer-birthdate"
              :class="{
                'form-control': true,
                'app__input-group__input': true,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !birth_date,
                app__loading: loading,
              }"
              v-model="birth_date"
              @change="v$.birth_date.$touch()"
              v-mask="'##/##/####'"
            />
          </template>

          <template v-slot:feedbacks>
            <small
              v-if="v$.birth_date.$error"
              class="app__input-group__feedbacks"
            >
              <span
                v-if="!v$.birth_date.required.$response"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["inform-birthday"] }}
              </span>
              <span
                v-else-if="
                  v$.birth_date.required.$response &&
                  !v$.birth_date.isBirthDate.$response
                "
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["valid-birthday"] }}
              </span>
              <span
                v-if="
                  v$.birth_date.required.$response &&
                  v$.birth_date.isBirthDate.$response &&
                  !v$.birth_date.isValidByMaxAge.$response
                "
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.definedMaxAge }}
              </span>
              <span
                v-if="
                  v$.birth_date.required.$response &&
                  v$.birth_date.isBirthDate.$response &&
                  !v$.birth_date.isValidByMinAge.$response
                "
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.definedMinAge }}
              </span>
            </small>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.birth_date"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.birth_date[0] }}
              </span>
            </small>
          </template>
        </app-input-group>
      </div>

      <div class="col-12 col-md-6">
        <app-input-group
          :label="this.langs.profile['placeholder-phone']"
          identifier="customer-phone"
          v-if="this.showPhone()"
          :loading="loading"
          :value="phone"
          :icon="{ prefix: 'fa', name: 'phone', flip: true }"
          :state="this.getValidatorState(v$.phone, backendErrors.phone)"
        >
          <template v-slot:input>
            <input
              type="tel"
              maxlength="14"
              id="customer-phone"
              :class="{
                'form-control': true,
                'app__input-group__input': true,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !phone,
                app__loading: loading,
              }"
              v-model="this.phone"
              @change="v$.phone.$touch()"
              @focus="this.clearBackendErrors()"
              :placeholder="this.langs.profile['home-phone']"
            />
          </template>

          <template v-slot:feedbacks>
            <small v-if="v$.phone.$error" class="app__input-group__feedbacks">
              <span
                v-if="validLengthForeignPhone(phone)"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["inform-phone"] }}
              </span>
              <span
                v-else-if="
                  v$.phone.required && !v$.phone.isValidPhoneNumber.$response
                "
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["valid-phone"] }}
              </span>
            </small>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.phone"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.phone[0] }}
              </span>
            </small>
          </template>
        </app-input-group>

        <app-input-group
          :label="this.langs.profile['cellphone']"
          identifier="customer-mobile_phone"
          v-if="this.showCellphone()"
          :loading="loading"
          :value="cellphone"
          :icon="{ prefix: 'fa', name: 'mobile-alt' }"
          :state="
            this.getValidatorState(v$.cellphone, backendErrors.mobile_phone)
          "
        >
          <template v-slot:input>
            <input
              type="tel"
              maxlength="15"
              id="customer-mobile_phone"
              :placeholder="this.langs.profile['cellphone']"
              :class="{
                'form-control': true,
                'app__input-group__input': true,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !cellphone,
                app__loading: loading,
              }"
              v-model="cellphone"
              @change="v$.cellphone.$touch()"
              @focus="this.clearBackendErrors()"
            />
          </template>

          <template v-slot:feedbacks>
            <small
              v-if="v$.cellphone.$error"
              class="app__input-group__feedbacks"
            >
              <span
                v-if="this.validLengthForeignPhone(cellphone)"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["inform-cellphone"] }}
              </span>
              <span
                v-else-if="
                  v$.cellphone.required &&
                  !v$.cellphone.isValidPhoneNumber.$response
                "
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["valid-cellphone"] }}
              </span>
            </small>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.mobile_phone"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.mobile_phone[0] }}
              </span>
            </small>
          </template>
        </app-input-group>

        <app-input-group
          v-if="!isForeign"
          label="RG (12.345.678-9)"
          identifier="customer-rg"
          :loading="loading"
          :value="rg"
          :icon="{ prefix: 'far', name: 'address-card' }"
          :state="this.getValidatorState(v$.rg, backendErrors.rg, 'rg')"
        >
          <template v-slot:input>
            <input
              type="tel"
              placeholder="RG"
              maxlength="15"
              id="customer-rg"
              :class="{
                'form-control': true,
                'app__input-group__input': true,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !rg,
                app__loading: loading,
              }"
              v-model="rg"
              @change="v$.rg.$touch()"
            />
          </template>

          <template v-slot:feedbacks>
            <small v-if="v$.rg.$error" class="app__input-group__feedbacks">
              <span
                v-if="!v$.rg.required.$response"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["inform-rg"] }}
              </span>
              <span
                v-if="
                  v$.rg.required.$response && !v$.rg.isValidLength.$response
                "
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["valid-rg"] }}
              </span>
            </small>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.rg"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.rg[0] }}
              </span>
            </small>
          </template>
        </app-input-group>

        <app-input-group
          :label="gender != 0 ? this.langs.profile.gender : ''"
          identifier="customer-gender"
          :loading="loading"
          :value="gender"
          :icon="{ prefix: 'fa', name: 'transgender' }"
          :state="this.getValidatorState(v$.gender, backendErrors.gender)"
        >
          <template v-slot:input>
            <select
              placeholder="Sexo"
              id="customer-gender"
              :class="{
                'form-control': true,
                'app__input-group__select': true,
                'app__input-group__select--has-float-label': gender,
                'app__input-group__select--is-empty': !gender,
                app__loading: loading,
              }"
              v-model="gender"
              @change="v$.gender.$touch()"
            >
              <option
                v-for="(option, index) in [
                  {
                    title: 'default',
                    value: '',
                    text: this.langs.profile.gender,
                    disabled: true,
                  },
                  {
                    title: 'male',
                    value: '1',
                    text: this.langs.profile.masc,
                  },
                  {
                    title: 'female',
                    value: '2',
                    text: this.langs.profile.fem,
                  },
                  ...(isFilledGenderRequired ? [] : [{
                    title: 'blank',
                    value: '3',
                    text: this.langs.profile.blank,
                  }]),
                ]"
                :key="index"
                :disabled="option.disabled"
                :selected="index === 0 ? 'selected' : false"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </template>

          <template v-slot:feedbacks>
            <small v-if="v$.gender.$error && isFilledGenderRequired" class="app__input-group__feedbacks">
              <span
                v-if="!v$.gender.required.$response"
                class="app__input-group__feedbacks__feedback"
              >
                {{ this.langs.profile["select-gender"] }}
              </span>
            </small>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.gender"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.gender[0] }}
              </span>
            </small>
          </template>
        </app-input-group>
      </div>
      <div class="col-12 pt-3">
        <div class="app__data-edit__input__wrapper">
          <input
            type="checkbox"
            class="app__data-edit__input__checkbox__input"
            id="customer-newsletter-term"
            value="2"
            @change="toggleNewsletterTerm"
          />
          <label
            for="customer-newsletter-term"
            class="app__data-edit__input__checkbox"
            :class="{
              'app__data-edit__input__checkbox--is-checked':
                isNewsletterAccepted,
            }"
          >
            <app-icon prefix="fa" name="check" v-show="isNewsletterAccepted">
            </app-icon>
          </label>
          <label
            for="customer-newsletter-term"
            class="app__data-edit__input__checkbox__label"
          >
            {{ this.langs.profile["newsletter-term"] }}
          </label>
        </div>
      </div>
    </div>
  </section>
</template>
